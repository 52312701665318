export const noSelect = {
  webkitTouchCallout: 'none' /* iOS Safari */,
  webkitUserSelect: 'none' /* Safari */,
  khtmlUserSelect: 'none' /* Konqueror HTML */,
  mozUserSelect: 'none' /* Old versions of Firefox */,
  msUserSelect: 'none' /* Internet Explorer/Edge */,
  userSelect:
    'none' /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */,
} as const

export const srOnly = {
  position: 'absolute',
  left: '-10000000px',
  width: '1px',
  height: '1px',
  padding: 0,
  margin: '-1px',
  overflow: 'hidden',
  clip: 'rect(0, 0, 0, 0)',
  whiteSpace: 'nowrap',
  borderWidth: 0,
} as const

export const ellipsize = {
  // width: 'max-content',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
} as const

export const breakpoints = {
  mobile: 'screen and (max-width: 480px)',
  tablet: 'screen and (min-width: 481px) and (max-width: 1024px)',
} as const

export const important = <T extends any>(value: T) => `${value} !important` as T
