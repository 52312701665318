export function objectFromKeys<T extends string | number | symbol, U>(
  keys: T[] | readonly T[],
  mapFn: (key: T, i: number) => U,
  filterFn?: (v: T | U) => boolean
) {
  return Object.fromEntries(
    keys
      .map((key, i) => [key, mapFn(key, i)])
      .filter((el) => {
        if (filterFn) {
          return filterFn(el[1])
        }
        return true
      })
  ) as Record<T, U>
}
