export const zIndices = {
  hidden: -1,
  zero: 0,
  base: 100,
  loadingOverlay: 200,
  nav: 300,
  sidenav: 400,
  drawer: 450,
  modalOverlay: 500,
  modal: 600,
  popupOverlay: 700,
  popup: 800,
  tooltip: 900,
  top: 5000,
  scrollbar: 10000,
}
