interface Options {
  element?: any
}

export const extractRawValue = (
  variable: string,
  { element: optionElement }: Options | undefined = {}
) => {
  const element = optionElement ?? document.documentElement
  const parsedVariable = variable.replace(/var\(--(.*?)(?:, (.*?))?\)/, '$1')

  return getComputedStyle(element).getPropertyValue(`--${parsedVariable}`)
}
