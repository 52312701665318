import isDeepEqual from 'fast-deep-equal'

/**
 *
 * @param prev The original form state
 * @param curr The update for state
 * @returns A filtered object containing only the keys that have been subject to a change
 */
export function extractDiff(prev?: Record<string, any> | null, curr?: Record<string, any> | null) {
  if (!prev || !curr || Array.isArray(curr)) return {}
  const diff: Record<string, any> = {}
  Object.entries(curr).forEach(([key, value]) => {
    if (!isDeepEqual(value, prev[key])) {
      diff[key] = value
    }
  })
  return diff
}
