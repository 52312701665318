import { TestProps } from 'typ'
import { BoxLayoutProps } from '../types'

export const extractBoxLayoutProps = (props: BoxLayoutProps & TestProps) => {
  const {
    m,
    mb,
    ml,
    mr,
    mt,
    mx,
    my,
    p,
    pb,
    px,
    py,
    pl,
    pr,
    pt,
    'data-cy': dataCy,
    'data-testid': dataTestId,
    alignSelf,
    bg,
    borderRadius,
    boxSizing,
    color,
    flexGrow,
    height,
    justifySelf,
    overflow,
    position,
    textAlign,
    width,
    maxWidth,
    maxHeight,
    minWidth,
    minHeight,
    ...others
  } = props

  return {
    colorProps: {
      color,
      bg,
    },
    layoutProps: {
      m,
      mb,
      ml,
      mr,
      mt,
      mx,
      my,
      p,
      px,
      py,
      pl,
      pr,
      pb,
      pt,
      alignSelf,
      borderRadius,
      boxSizing,
      flexGrow,
      height,
      justifySelf,
      overflow,
      position,
      textAlign,
      width,
      maxWidth,
      maxHeight,
      minWidth,
      minHeight,
    },
    testProps: {
      'data-testid': dataTestId,
      'data-cy': dataCy,
    },
    ...others,
  }
}
