import { vars } from './theme.css'

export const gradientStarts = {
  blue0: '#0E42F9',
  green0: '#81D614',
  red0: '#F9460E',
  gray0: '#383A47',
}

export const gradientStops = {
  blue0: '#0EC3EB',
  green0: '#1EBAEC',
  red0: '#AA1EEC',
  gray0: '#857E8E',
}

export const gradients = {
  blue0: `linear-gradient(97.57deg, ${gradientStarts.blue0} -9.53%, ${gradientStops.blue0} 114.72%)`,
  blue1: `linear-gradient(${vars.colorsBackground.element}, ${vars.colorsBackground.element}) padding-box, linear-gradient(155deg, rgba(14, 66, 249, 0.62)  0%,
    rgba(14, 195, 235, 0.16) 100%)
     border-box`,
  blue2: `linear-gradient(${vars.colorsBackground.element}, ${vars.colorsBackground.element}) padding-box, linear-gradient(155deg, rgba(14, 66, 249, 1)  0%,
    rgba(14, 195, 235, 1) 100%)
     border-box`,
  green0: `linear-gradient(97.57deg, ${gradientStarts.green0} -9.53%, ${gradientStops.green0}  114.72%)`,
  red0: `linear-gradient(97.57deg, ${gradientStarts.red0} -9.53%, ${gradientStops.red0} 114.72%)`,
  gray0: `linear-gradient(97.57deg, ${gradientStarts.gray0} -9.53%, ${gradientStops.gray0} 114.72%)`,
}

const baseGradientSyntax = {
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textFillColor: 'transparent',
  backgroundSize: '100%',
  maxWidth: 'max-content',
}

export const textGradients = {
  blue: {
    ...baseGradientSyntax,
    backgroundColor: gradientStarts.blue0,
    backgroundImage: gradients.blue0,
  },
  green: {
    ...baseGradientSyntax,
    backgroundColor: gradientStarts.green0,
    backgroundImage: gradients.green0,
  },
  red: {
    ...baseGradientSyntax,
    backgroundColor: gradientStarts.red0,
    backgroundImage: gradients.red0,
  },
  gray: {
    ...baseGradientSyntax,
    backgroundColor: gradientStarts.gray0,
    backgroundImage: gradients.gray0,
  },
}
