import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef, PropsWithChildren } from 'react'
import { TestProps } from 'typ'
import { Display, sprinkles } from 'vanilla-extract-config'
import { BoxLayoutProps } from '../../types'
import { extractBoxLayoutProps } from '../../utils'

export type BoxOwnProps = {
  as?: React.ElementType
  display?: Exclude<Display, 'flex' | 'grid'>
}

// TODO: DECIDE WHETHER WE WANT TO USE COLOR PROPS TOO
export type BoxProps = PropsWithChildren &
  ComponentPropsWithoutRef<'div'> &
  TestProps &
  BoxLayoutProps &
  BoxOwnProps

export const Box = forwardRef<HTMLDivElement, BoxProps>(
  ({ as: Component, children, className, display, ...props }, ref) => {
    const Element = Component || 'div'

    const { layoutProps, testProps, colorProps, ...coreProps } = extractBoxLayoutProps(props)
    return (
      <Element
        {...testProps}
        {...coreProps}
        className={clsx([sprinkles({ ...layoutProps, ...colorProps, display }), className])}
        ref={ref}
      >
        {children}
      </Element>
    )
  }
)

Box.displayName = 'Box'
