import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef, PropsWithChildren } from 'react'
import { TestProps } from 'typ'
import {
  AlignItems,
  Columns,
  Display,
  FlexDirection,
  FlexWrap,
  JustifyContent,
  PlaceItems,
  Spacing,
  sprinkles,
} from 'vanilla-extract-config'
import { BoxLayoutProps } from '../../types'
import { extractBoxLayoutProps } from '../../utils'

export type GridOwnProps = {
  align?: AlignItems
  as?: React.ElementType
  columnGap?: Spacing
  columns?: Columns
  direction?: FlexDirection
  display?: Extract<Display, 'grid' | 'none'>
  flexWrap?: FlexWrap
  justify?: JustifyContent
  place?: PlaceItems
  rowGap?: Spacing
  gap?: Spacing
}

export type GridProps = PropsWithChildren &
  ComponentPropsWithoutRef<'div'> &
  TestProps &
  BoxLayoutProps &
  GridOwnProps

export const Grid = forwardRef<HTMLDivElement, GridProps>(
  (
    {
      align,
      as: Component,
      children,
      className,
      columnGap,
      columns,
      display = 'grid',
      flexWrap,
      justify,
      place,
      rowGap,
      gap,
      ...props
    },
    ref
  ) => {
    const Element = Component || 'div'

    const { layoutProps, testProps, colorProps, ...coreProps } = extractBoxLayoutProps(props)
    return (
      <Element
        {...testProps}
        {...coreProps}
        className={clsx([
          sprinkles({
            ...layoutProps,
            ...colorProps,
            align,
            columnGap,
            columns,
            display,
            flexWrap,
            justify,
            place,
            rowGap,
            gap,
          }),
          className,
        ])}
        ref={ref}
      >
        {children}
      </Element>
    )
  }
)

Grid.displayName = 'Grid'
